import { useOptionalUser } from "hooks/useUser";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useIsDevmode } from "utils/utils.ts";
declare global {
  interface Window {
    axeptio_cookieConsent: (category: string) => boolean;
  }
}

interface AxeptioCookie {
  $$token: string;
  $$date: string;
  $$cookiesVersion: {
    name: string;
    identifier: string;
  };
  $$googleConsentMode: {
    version: number;
    analytics_storage: string;
    ad_storage: string;
    ad_user_data: string;
    ad_personalization: string;
  };
  $$completed: boolean;
}

export const GoogleAnalytics = ({
  GTM_ID,
  nonce,
}: {
  GTM_ID: string;
  nonce: string;
}) => {
  const isDev = useIsDevmode();
  const user = useOptionalUser();

  useEffect(() => {
    if (isDev || user?.authOptions.isAdmin) return;

    const loadGTM = () => {
      const script = document.createElement("script");
      script.nonce = nonce;
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_ID}');
      `;
      document.head.appendChild(script);
    };

    const checkConsentAndLoadGTM = () => {
      const axeptioCookieRaw = Cookies.get("axeptio_cookies");
      if (axeptioCookieRaw) {
        try {
          // @ts-ignore
          const axeptioCookie: AxeptioCookie = JSON.parse(
            decodeURIComponent(axeptioCookieRaw),
          );

          if (
            axeptioCookie.$$googleConsentMode.analytics_storage === "granted"
          ) {
            loadGTM();
          }
        } catch (error) {
          console.error("Erreur lors du parsing du cookie Axeptio:", error);
        }
      }
    };

    // Vérifier immédiatement
    checkConsentAndLoadGTM();

    // Vérifier périodiquement (par exemple, toutes les 2 secondes)
    const intervalId = setInterval(checkConsentAndLoadGTM, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [isDev, user, GTM_ID, nonce]);

  return null;
};
