import { HomeSection } from "components/Homepage/HomeSection.tsx";
import { Facebook as FacebookIcon } from "components/Icons/Facebook";
import FullLogo from "components/Media/FullLogo.tsx";
import LogoIcon from "components/Media/LogoIcon.tsx";
import { Label } from "components/UI/Label";
import { Paragraph } from "components/UI/Paragraph";

import { Instagram } from "components/Icons/Instagram";
import { LinkedIn } from "components/Icons/LinkedIn";
import { ObfuscatableLink } from "components/Interface/ObfuscatableLink";
import { Twitter } from "iconoir-react";
import bpiLogo from "./logo-bpi.png";
import bpiCoqLogo from "./logo-coq.png";
import planetLogo from "./logo-planet.png";
type NavigationType = {
  categories: {
    name: string;
    link: string;
    obfuscate: boolean;
    links: { name: string; href: string; obfuscate: boolean }[];
    images?: {
      image: string;
      link: string;
      alt: string;
      width: number;
      height: number;
    }[];
  }[];
};
const navigation: NavigationType = {
  categories: [
    {
      name: "Solutions",
      link: "/solutions",
      obfuscate: true,
      links: [
        {
          name: "Nos solutions",
          href: "/solutions",
          obfuscate: false,
        },
        {
          name: "Location bennes",
          href: "/solutions/location-de-bennes",
          obfuscate: false,
        },
        {
          name: "Enlèvement Big bags",
          href: "/solutions/enlevement-big-bag",
          obfuscate: false,
        },
      ],
    },
    {
      name: "Conseil",
      link: "/conseil",
      links: [
        {
          name: "Prestation Conseil",
          href: "/conseil",
          obfuscate: false,
        },
        {
          name: "Nos conseils d'experts",
          href: "/conseil",
          obfuscate: true,
        },
        {
          name: "Trouver une déchetterie",
          href: "/dechetterie",
          obfuscate: true,
        },
      ],
      obfuscate: false,
    },
    // {
    //   name: "A Propos",
    //   link: "#",
    //   obfuscate: true,
    //   links: [
    //     {
    //       name: "Blog",
    //       href: "/blog",
    //       obfuscate: false,
    //     },
    //     {
    //       name: "Déchetterie",
    //       href: "/dechetterie",
    //       obfuscate: false,
    //     },
    //   ],
    // },
    // {
    //   name: "Contact",
    //   link: "/contact",
    //   obfuscate: false,
    //   links: [
    //     { name: "Formulaire contact", href: "/contact", obfuscate: true },
    //     { name: "WhatsApp", href: "/contact", obfuscate: true },
    //   ],
    // },
    {
      name: "Connexion",
      link: "/login",
      obfuscate: true,
      links: [
        {
          name: "Espace clients",
          href: "/dashboard",
          obfuscate: true,
        },
        {
          name: "Espace partenaires",
          href: "/provider/dashboard",
          obfuscate: true,
        },
        {
          name: "Devenir partenaire",
          href: "/register-provider",
          obfuscate: true,
        },
      ],
    },
  ],
};

const socials = [
  // {
  //   name: "Facebook",
  //   img: <Facebook className="shrink-0 size-5" aria-hidden='true' />,
  //   href: "https://www.facebook.com/profile.php?id=100076957843606",
  // },
  {
    name: "Facebook",
    img: <FacebookIcon className="shrink-0 size-5" aria-hidden="true" />,
    href: "https://www.facebook.com/profile.php?id=100076957843606",
  },
  {
    name: "Instagram",
    img: <Instagram className="shrink-0 size-5" aria-hidden="true" />,
    href: "https://www.instagram.com/goodcollect",
  },

  {
    name: "LinkedIn",
    img: <LinkedIn className="shrink-0 size-5" aria-hidden="true" />,
    href: "https://www.linkedin.com/company/goodcollect",
  },
  {
    name: "Twitter",
    img: (
      <Twitter
        className="h-full w-full shrink-0 size-5 fill-white"
        aria-hidden="true"
      />
    ),
    href: "https://twitter.com/Goodcollectco",
  },
];
const ClintLogo = () => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2"
    >
      <path
        d="M-0.000149034 9.02121C-0.000149178 13.9261 4.12887 17.9165 9.2041 17.9165L9.2041 14.6327C5.97832 14.6327 3.39769 12.0971 3.39769 9.02121C3.39769 5.90372 6.02133 3.40973 9.2041 3.40973L9.2041 0.125977C4.12887 0.125976 -0.00014889 4.11636 -0.000149034 9.02121Z"
        fill="white"
      />
      <path
        d="M17.9781 -1.00604e-07C16.9028 -1.56494e-07 16.0426 0.831322 16.0426 1.87047C16.0426 2.90963 16.9028 3.69938 17.9781 3.69938C19.0534 3.69938 19.9136 2.86806 19.9136 1.87047C19.9136 0.831322 19.0534 -4.47127e-08 17.9781 -1.00604e-07Z"
        fill="white"
      />
      <path
        d="M17.9785 14.1338C16.8602 14.1338 15.957 15.0067 15.957 16.0875C15.957 17.1682 16.8602 17.9996 17.9785 17.9996C19.0968 17.9996 20 17.1266 20 16.0875C20 15.0067 19.0968 14.1338 17.9785 14.1338Z"
        fill="white"
      />
    </svg>
  );
};
const Collaborator = [
  {
    // image: '/images/logo-coq.png',
    image: bpiCoqLogo,
    link: "https://www.bpifrance.fr/communaute-du-coq-vert",
    alt: "Coq vert BPI",
    width: 68,
    height: 68,
  },
  {
    link: "https://www.bpifrance.fr",
    // image: '/images/logo-bpi.png',
    image: bpiLogo,
    alt: "logo jaune BPI France",
    width: 68,
    height: 68,
  },
  {
    link: "https://entrepreneurspourlaplanete.org/",
    // image: '/images/logo-planet.png',
    image: planetLogo,
    alt: "Logo Entrepreneurs pour la planète",
    width: 68,
    height: 68,
  },
];

const Footer = () => {
  return (
    <HomeSection
      color="linearMidnightblue"
      horizontalPadding="none"
      verticalPadding="none"
    >
      <footer
        aria-labelledby="footer-heading"
        className="relative mx-auto flex min-h-[461px] w-full flex-col justify-between gap-y-6 px-5 pb-10 pt-10 lg:max-w-[1440px] lg:px-20 lg:pb-[60px] lg:pt-20"
      >
        <div className="flex flex-wrap justify-between gap-6 gap-y-10">
          <ObfuscatableLink
            obfuscate
            to="/"
            aria-label="Accéder à la page d'accueil"
            className="inline-flex h-fit items-center gap-5"
          >
            <div className={`text-primary h-[41px] w-[41px]`}>
              <LogoIcon />
            </div>
            <div
              className={`flex h-8 w-auto max-w-[221px] flex-shrink-0 items-center text-white`}
            >
              <FullLogo />
            </div>
          </ObfuscatableLink>
          <div className="flex flex-row flex-wrap gap-4 gap-y-10">
            {navigation.categories.map((cat) => (
              <div
                key={cat.name}
                className="flex w-[194px] flex-col items-start gap-5"
              >
                <Label size={"S"} as="h4" className="text-green-300">
                  {cat.name}
                </Label>
                <ul className="flex flex-col items-start gap-5">
                  {cat.links.map((item) => (
                    <li key={item.name}>
                      <ObfuscatableLink
                        obfuscate={item.obfuscate}
                        to={item.href}
                      >
                        <Paragraph
                          size={"sm"}
                          variant={"white"}
                          className="text-base font-normal text-white hover:cursor-pointer hover:text-green-500"
                        >
                          {item.name}
                        </Paragraph>
                      </ObfuscatableLink>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
            <div className={"flex w-[194px] flex-col items-start gap-5"}>
              <Label size={"S"} as="h4" className="text-green-300">
                Nos partenaires de confiance
              </Label>
              <ul className="flex items-start gap-5">
                {Collaborator.map(({ image, link, alt, width, height }) => (
                  <li key={image}>
                    <ObfuscatableLink obfuscate to={link}>
                      <img
                        src={image}
                        alt={alt}
                        width={width}
                        height={height}
                        loading="lazy"
                      />
                    </ObfuscatableLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <div className="relative flex flex-col-reverse justify-between gap-8 text-base text-white lg:flex-row">
          <div className="order-2 inline-flex flex-col items-start gap-5 lg:order-1">
            <Label size={"S"} as="p" className="text-green-300">
              Nous suivre
            </Label>
            <div className="flex items-start gap-3">
              {socials.map(({ href, img, name }) => (
                <ObfuscatableLink
                  obfuscate
                  aria-label={`Accéder à notre page ${name}`}
                  to={href}
                  key={href}
                  className="bg-primary flex items-center justify-center h-10 w-10 rounded p-2.5 text-white"
                >
                  {img} <span className="sr-only">{name}</span>
                </ObfuscatableLink>
              ))}
            </div>
          </div>
          <div className="order-2 flex shrink-0 flex-col flex-wrap gap-4 sm:flex-row sm:items-end">
            {[
              {
                name: "Mentions légales",
                link: "/legal",
                obfuscate: false,
              },
              {
                name: "Politique de confidentialité",
                link: "/rgpd",
                obfuscate: false,
              },
              { name: "CGV", link: "/cgv", obfuscate: false },
            ].map(({ name, obfuscate, link }) => (
              <ObfuscatableLink
                obfuscate={obfuscate}
                to={link}
                prefetch="intent"
                key={link}
                className="h-fit"
              >
                <Label
                  size={"XS"}
                  as="p"
                  variant={"white"}
                  className="hover:text-green-300"
                >
                  {name}
                </Label>
              </ObfuscatableLink>
            ))}
          </div>
          <div className="absolute bottom-0 right-0 order-1 flex w-fit items-center sm:static lg:order-3  justify-end flex-col gap-2 ">
            <Label size={"XS"} variant={"white"} className="h-fit w-fit">
              &copy; Goodcollect - {new Date().getFullYear()}
            </Label>
            <Label size={"XS"} variant={"white"} className="h-fit w-fit flex">
              <ClintLogo />
              Designed by Clint
            </Label>
          </div>
        </div>
      </footer>
    </HomeSection>
  );
};

export default Footer;
