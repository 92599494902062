import { useEnv } from "app/root";
import { useNonce } from "app/utils/nonce-provider";
import { useEffect } from "react";

export const AxeptioBanner = () => {
  const env = useEnv();
  const nonce = useNonce();

  useEffect(() => {
    if (!env?.AXEPTIO_CLIENT_ID) return;

    const script = document.createElement("script");
    script.async = true;
    script.src = "//static.axept.io/sdk.js";
    script.nonce = nonce;
    script.dataset.id = env.AXEPTIO_CLIENT_ID;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [env?.AXEPTIO_CLIENT_ID, nonce]);

  return null;
};
